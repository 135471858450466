import http from '../services/http';

export default class Report {
  static fetchReports(params) {
    return http.get('/reports', {
      params
    });
  }

  static setStatus(id) {
    return http.patch(`/reports/${id}/set-status`, { status: 'VIEWED' });
  }

  static sendNotifyEmail(body) {
    return http.post('/reports/send-notify', body);
  }

  static deleteComment(postId, commentId) {
    return http.delete(`posts/${postId}/comments/${commentId}`);
  }
}
