import http from '../services/http';

export default class Post {
  static fetchPosts(params) {
    return http.get('/posts/admin-search', {
      params
    });
  }

  static disablePost(id, disabledReason) {
    return http.patch(`/posts/${id}/banned`, { disabledReason });
  }

  static enablePost(id) {
    return http.delete(`/posts/${id}/banned`);
  }

  static editPost(id, body) {
    return http.patch(`/posts/admin-update/${id}`, body);
  }
}
