import { handleActions } from 'redux-actions';

const initialState = {
  wallets: {
    result: null,
    error: null,
    requesting: false
  },
  verify: {
    result: null,
    error: null,
    requesting: false
  },
  banned: {
    result: null,
    error: null,
    requesting: false
  },
  editWallet: {
    result: null,
    error: null,
    requesting: false
  },
  options: {
    result: null,
    error: null,
    requesting: false
  }
};

export const wallet = handleActions({
  FETCH_WALLETS_REQUEST: (state) => ({
    ...state,
    wallets: {
      ...state.wallets,
      requesting: true,
      error: null
    }
  }),
  FETCH_WALLETS_SUCCESS: (state, { payload }) => ({
    ...state,
    wallets: {
      ...state.wallets,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_WALLETS_FAIL: (state, { payload }) => ({
    ...state,
    wallets: {
      ...state.wallets,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  SET_VERIFY_REQUEST: (state) => ({
    ...state,
    verify: {
      ...state.verify,
      requesting: true,
      error: null
    }
  }),
  SET_VERIFY_SUCCESS: (state, { payload }) => ({
    ...state,
    verify: {
      ...state.verify,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SET_VERIFY_FAIL: (state, { payload }) => ({
    ...state,
    verify: {
      ...state.verify,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  SET_BANNED_REQUEST: (state) => ({
    ...state,
    banned: {
      ...state.banned,
      requesting: true,
      error: null
    }
  }),
  SET_BANNED_SUCCESS: (state, { payload }) => ({
    ...state,
    banned: {
      ...state.banned,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SET_BANNED_FAIL: (state, { payload }) => ({
    ...state,
    banned: {
      ...state.banned,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  EDIT_WALLET_REQUEST: (state) => ({
    ...state,
    editWallet: {
      ...state.editWallet,
      requesting: true,
      error: null
    }
  }),
  EDIT_WALLET_SUCCESS: (state, { payload }) => ({
    ...state,
    editWallet: {
      ...state.editWallet,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  EDIT_WALLET_FAIL: (state, { payload }) => ({
    ...state,
    editWallet: {
      ...state.editWallet,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  FETCH_WALLET_OPTIONS_REQUEST: (state) => ({
    ...state,
    options: {
      ...state.options,
      requesting: true,
      error: null
    }
  }),
  FETCH_WALLET_OPTIONS_SUCCESS: (state, { payload }) => ({
    ...state,
    options: {
      ...state.options,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_WALLET_OPTIONS_FAIL: (state, { payload }) => ({
    ...state,
    options: {
      ...state.options,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  SET_INITIAL_USER_DATA: () => initialState,
}, initialState);

export default wallet;
