import { handleActions } from 'redux-actions';
import { has, isEmpty } from 'lodash';

const initialState = {
  firstLogin: {
    result: null,
    qrcodeImage: null,
    error: null,
    requesting: false
  },
  login: {
    result: null,
    error: null,
    requesting: false
  },
  connectWallet: {
    result: null,
    error: null,
    requesting: false
  },
  userAccount: {
    accounts: [],
    error: null,
    requesting: false,
  },
  changePass: {
    result: null,
    error: null,
    requesting: false,
  },
  forgotPass: {
    result: null,
    error: null,
    requesting: false,
  },
  resetPass: {
    result: null,
    error: null,
    requesting: false,
  },
  createAccount: {
    result: null,
    error: null,
    requesting: false,
  },
  chainId: '',
  wrongNetwork: false
};

export const user = handleActions({
  /** LOGIN **/
  FIRST_LOGIN_REQUEST: (state) => ({
    ...state,
    firstLogin: {
      ...state.firstLogin,
      requesting: true,
      error: null
    }
  }),
  FIRST_LOGIN_SUCCESS: (state, { payload }) => ({
    ...state,
    firstLogin: {
      ...state.firstLogin,
      requesting: false,
      result: payload.data,
      qrcodeImage: payload.qrcodeImage,
      error: null
    }
  }),
  FIRST_LOGIN_FAIL: (state, { payload }) => ({
    ...state,
    firstLogin: {
      ...state.firstLogin,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  LOGIN_REQUEST: (state) => ({
    ...state,
    login: {
      ...state.login,
      requesting: true,
      error: null
    }
  }),
  LOGIN_SUCCESS: (state, { payload }) => ({
    ...state,
    login: {
      ...state.login,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  LOGIN_FAIL: (state, { payload }) => ({
    ...state,
    login: {
      ...state.login,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  CHANGE_PASSWORD_REQUEST: (state) => ({
    ...state,
    changePass: {
      ...state.changePass,
      requesting: true,
      error: null
    }
  }),
  CHANGE_PASSWORD_SUCCESS: (state, { payload }) => ({
    ...state,
    changePass: {
      ...state.changePass,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  CHANGE_PASSWORD_FAIL: (state, { payload }) => ({
    ...state,
    changePass: {
      ...state.changePass,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  FORGOT_PASSWORD_REQUEST: (state) => ({
    ...state,
    forgotPass: {
      ...state.forgotPass,
      requesting: true,
      error: null
    }
  }),
  FORGOT_PASSWORD_SUCCESS: (state, { payload }) => ({
    ...state,
    forgotPass: {
      ...state.forgotPass,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FORGOT_PASSWORD_FAIL: (state, { payload }) => ({
    ...state,
    forgotPass: {
      ...state.forgotPass,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  RESET_PASSWORD_REQUEST: (state) => ({
    ...state,
    resetPass: {
      ...state.resetPass,
      requesting: true,
      error: null
    }
  }),
  RESET_PASSWORD_SUCCESS: (state, { payload }) => ({
    ...state,
    resetPass: {
      ...state.resetPass,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  RESET_PASSWORD_FAIL: (state, { payload }) => ({
    ...state,
    resetPass: {
      ...state.resetPass,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  CREATE_ACCOUNT_REQUEST: (state) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      requesting: true,
      error: null
    }
  }),
  CREATE_ACCOUNT_SUCCESS: (state, { payload }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  CREATE_ACCOUNT_FAIL: (state, { payload }) => ({
    ...state,
    createAccount: {
      ...state.createAccount,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  /** INITIAL USER DATA **/
  SET_INITIAL_USER_DATA: () => initialState,

  /** CONNECT WALLET **/
  CONNECT_WALLET_REQUEST: (state) => ({
    ...state,
    connectWallet: {
      ...state.connectWallet,
      requesting: true,
    },
  }),
  CONNECT_WALLET_SUCCESS: (state) => ({
    ...state,
    connectWallet: {
      ...state.connectWallet,
      requesting: false,
      isConnect: true,
      error: null,
    },
  }),
  CONNECT_WALLET_FAIL: (state, { payload }) => ({
    ...state,
    connectWallet: {
      ...state.connectWallet,
      requesting: false,
      error: payload.error,
    },
  }),

  /** SET USER ACCOUNTS **/
  SET_USER_ACCOUNTS: (state, { payload }) => ({
    ...state,
    userAccount: {
      ...state.userAccount,
      accounts: has(payload, 'accounts') ? payload.accounts : state.userAccount.accounts
    },
    connectWallet: {
      ...state.connectWallet,
      ...{ isConnect: has(payload, 'accounts') ? !isEmpty(payload.accounts) : !isEmpty(state.userAccount.accounts) },
    },
    chainId: has(payload, 'chainId') ? payload.chainId : state.chainId
  }),

  SET_NETWORK: (state, { payload }) => ({
    ...state,
    chainId: payload,
  }),

  SET_WRONG_NETWORK: (state, { payload }) => ({
    ...state,
    wrongNetwork: payload,
  }),
}, initialState);

export default user;
