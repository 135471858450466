const options = [
  {
    key: 'dashboard',
    label: 'Statistics',
    leftIcon: 'ion-aperture'
  },
  {
    key: 'wallets',
    label: 'Users',
    leftIcon: 'ion-aperture'
  },
  {
    key: 'categories',
    label: 'Categories',
    leftIcon: 'ion-ios-bookmarks'
  },
  {
    key: 'posts',
    label: 'Posts',
    leftIcon: 'ion-music-note'
  },
  {
    key: 'transactions',
    label: 'Transactions',
    leftIcon: 'ion-android-stopwatch'
  },
  {
    key: 'report',
    label: 'Reports',
    leftIcon: 'ion-arrow-graph-up-right'
  },
  {
    key: 'whitelists',
    label: 'Whitelists',
    leftIcon: 'ion-arrow-graph-up-right'
  },
  {
    key: 'setting',
    label: 'Setting',
    leftIcon: 'ion-ios-settings-strong'
  }
];
export default options;
