import http from '../services/http';

export default class Category {
  static fetchCategories(params) {
    return http.get('/categories/search', {
      params
    });
  }

  static addCategory(body) {
    return http.post('/categories', body);
  }

  static editCategory(id, body) {
    return http.patch(`/categories/${id}`, body);
  }

  static deleteCategory(id) {
    return http.delete(`/categories/${id}`);
  }

  static fetchAllCategories() {
    return http.get('/categories');
  }
}
