import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Layout } from 'antd';
import { bindActionCreators } from 'redux';
import { Debounce } from 'react-throttle';
import WindowResizeListener from 'react-window-size-listener';
import {
  Redirect,
  Route,
  withRouter
} from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import $ from 'jquery';
import { get } from 'lodash';

import {
  toggleAll
} from '../../actions/common';

import Topbar from '../Topbar/Topbar';
import Sidebar from '../Sidebar/Sidebar';

import './global.css';
import AppHolder from './commonStyle';
import themes from '../../settings/themes';

import {
  siteConfig,
  themeConfig
} from '../../settings';
import http from '../../services/http';

const { Content, Footer } = Layout;

window.$ = $;
window.jQuery = $;
const customTopbar = [];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    const { token } = props;

    if (token) {
      http.setAuthorizationHeader(token);
    }
  }

  // componentDidMount() {
  //   const script = document.createElement('script');
  //   script.src = process.env.PUBLIC_URL + '/js/printThis.js';
  //   script.async = true;
  //   document.body.appendChild(script);
  // }

  render() {
    const { url } = this.props.match;
    const { height, path } = this.props;
    const appHeight = window.innerHeight;
    const isCustomTopbar = customTopbar.includes(path);

    return (
      <ThemeProvider theme={themes[themeConfig.theme]}>
        <AppHolder>
          <Layout style={{ height: appHeight }}>
            <Debounce time="1000" handler="onResize">
              <WindowResizeListener
                onResize={windowSize =>
                  this.props.toggleAll(
                    windowSize.windowWidth,
                    windowSize.windowHeight
                  )
                }
              />
            </Debounce>
            {!isCustomTopbar && <Topbar url={url}/>}
            <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
              <Sidebar url={url} />
              <Layout
                className="isoContentMainLayout"
                style={{
                  height
                }}
              >
                <Content
                  className="isomorphicContent"
                  style={{
                    padding: `${isCustomTopbar ? 0 : '70px 0 0'}`,
                    flexShrink: '0',
                    background: '#f1f3f6',
                    position: 'relative'
                  }}
                >
                  {this.props.children}
                </Content>
                <Footer
                  style={{
                    background: '#ffffff',
                    textAlign: 'center',
                    borderTop: '1px solid #ededed'
                  }}
                >
                  {siteConfig.footerText}
                </Footer>
              </Layout>

            </Layout>
          </Layout>
        </AppHolder>
      </ThemeProvider>
    );
  }
}

const AppRoute = ({ component: Component, isAuthenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          isAuthenticated ? (
            <App {...rest}>
              <Component {...props} />
            </App>
          ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location }
              }}
            />
          )
        );
      }}
    />
  );
};

export default withRouter(connect(
  state => ({
    height: state.common.height,
    token: get(state, 'user.login.result.token')
  }),
  dispatch => bindActionCreators({
    toggleAll
  }, dispatch)
)(AppRoute));
