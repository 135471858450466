import React, { Component } from 'react';
import {
  Form,
  Modal,
  Upload
} from 'antd';
import { get } from 'lodash';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class UpdateImageValidation extends Component {
  static defaultProps = {
    listType: 'picture-card'
  };

  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: 'previewImage'
    };
  }

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true
    });
  };

  getUploadFieldProps = () => {
    const { form, field } = this.props;
    const { setFieldValue } = form;

    const uploadProps = {
      onRemove: (file) => {
        setFieldValue(field.name, null);
      },
      beforeUpload: (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          file.url = reader.result;
          setFieldValue(field.name, file);
        };
        return false;
      }
    };

    return { ...uploadProps };
  };

  handleCancel = () => this.setState({ previewVisible: false });

  render() {
    const {
      previewVisible,
      previewImage
    } = this.state;

    const {
      id,
      label,
      field,
      form,
      containerClassName,
      accept,
      required,
      fieldLayout,
      listType
    } = this.props;

    const uploadProps = this.getUploadFieldProps();

    const { errors } = form;
    const fieldError = get(errors, `${field.name}`);
    const validateStatus = fieldError ? 'error' : '';
    const value = field.value;

    return (
      <Form.Item
        id={id}
        label={label}
        {...fieldLayout}
        help={fieldError}
        required={required}
        className={containerClassName}
        validateStatus={validateStatus}
      >
        <Upload
          accept={accept || 'image/*'}
          name="file"
          fileList={value ? [value] : []}
          listType={listType}
          {...uploadProps}
          onPreview={this.handlePreview}
          style={{ width: '100%' }}
        >
          {!value && <div className="ant-upload-text">Upload</div>
          }
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Form.Item>
    );
  }
}

export default UpdateImageValidation;
