import http from '../services/http';

export default class Dashboard {
  static fetchDetails(params) {
    return http.get('/statistics/details', {
      params
    });
  }
  static fetchOverview() {
    return http.get('/statistics/overview');
  }

  static exportDetails(params) {
    return http.get('/statistics/export', {
      params,
      responseType: 'blob'
    });
  }
}
