import { createAction, createActions } from 'redux-actions';
import { getView } from '../utils/common';
import Api from '../api';

export const changeCurrent = createAction('CHANGE_CURRENT');
export const changeOpenKeys = createAction('CHANGE_OPEN_KEYS');
export const toggleCollapsed = createAction('TOGGLE_COLLAPSED');
export const handleToggleAll = createAction('HANDLE_TOGGLE_ALL');
export const toggleOpenDrawer = createAction('TOGGLE_OPEN_DRAWER');

export const toggleAll = (width, height) => (dispatch) => {
  const view = getView(width);
  const collapsed = view !== 'DesktopView';
  dispatch(handleToggleAll({ collapsed, view, height }));
};

/** UPLOAD MEDIA **/
const { uploadMediaRequest, uploadMediaSuccess, uploadMediaFail } = createActions({
  UPLOAD_MEDIA_REQUEST: () => { },
  UPLOAD_MEDIA_SUCCESS: data => ({ data }),
  UPLOAD_MEDIA_FAIL: error => ({ error }),
});

export const uploadMedia = body => (dispatch) => {
  dispatch(uploadMediaRequest());

  return new Promise(resolve => {
    return Api.Common.uploadMedia(body)
      .then(({ data }) => {
        dispatch(uploadMediaSuccess(data));
        resolve(data);
      })
      .catch((error) => {
        dispatch(uploadMediaFail(error));
        resolve();
      });
  });
};

const { fetchContractsRequest, fetchContractsSuccess, fetchContractsFail } = createActions({
  FETCH_CONTRACTS_REQUEST: () => { },
  FETCH_CONTRACTS_SUCCESS: data => ({ data }),
  FETCH_CONTRACTS_FAIL: error => ({ error }),
});

export const fetchContracts = () => (dispatch) => {
  dispatch(fetchContractsRequest());

  return new Promise(resolve => {
    return Api.Common.fetchContracts()
      .then(({ data }) => {
        dispatch(fetchContractsSuccess(data));
        resolve(data);
      })
      .catch((error) => {
        dispatch(fetchContractsFail(error));
        resolve();
      });
  });
};

const { statisticsSoldRequest, statisticsSoldSuccess, statisticsSoldFail } = createActions({
  STATISTICS_SOLD_REQUEST: () => { },
  STATISTICS_SOLD_SUCCESS: data => ({ data }),
  STATISTICS_SOLD_FAIL: error => ({ error }),
});

export const statisticsSold = () => (dispatch) => {
  dispatch(statisticsSoldRequest());

  return new Promise(resolve => {
    return Api.Common.statisticsSold()
      .then(({ data }) => {
        dispatch(statisticsSoldSuccess(data));
        resolve(data);
      })
      .catch((error) => {
        dispatch(statisticsSoldFail(error));
        resolve();
      });
  });
};

const { statisticsWalletsRequest, statisticsWalletsSuccess, statisticsWalletsFail } = createActions({
  STATISTICS_WALLETS_REQUEST: () => { },
  STATISTICS_WALLETS_SUCCESS: data => ({ data }),
  STATISTICS_WALLETS_FAIL: error => ({ error }),
});

export const statisticsWallets = () => (dispatch) => {
  dispatch(statisticsWalletsRequest());

  return new Promise(resolve => {
    return Api.Common.statisticsWallets()
      .then(({ data }) => {
        dispatch(statisticsWalletsSuccess(data));
        resolve(data);
      })
      .catch((error) => {
        dispatch(statisticsWalletsFail(error));
        resolve();
      });
  });
};
