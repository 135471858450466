import http from '../services/http';

export default class User {
  static firstLogin(body) {
    return http.post('/users/login', body);
  }

  static generateQrcode(body) {
    return http.post('/2fa/generate', body, {
      responseType: 'arraybuffer'
    });
  }

  static login(body) {
    return http.post('/2fa/authenticate', body);
  }

  static changePassword(body) {
    return http.post('/users/change-password', body);
  }

  static forgotPassword(params) {
    return http.get('/users/forgot-password', {
      params
    });
  }

  static resetPassword(body) {
    return http.post('/users/reset-password', body);
  }

  static forgot2FA(body) {
    return http.post('/users/forgot-2fa', body);
  }

  static createAccount(body) {
    return http.post('/users/register', body);
  }
}
