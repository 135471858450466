import http from '../services/http';

export default class Wallet {
  static fetchWallets(params) {
    return http.get('/wallets', {
      params
    });
  }

  static setVerify(id, body) {
    return http.patch(`/wallets/${id}/set-verify`, body);
  }

  static setBanned(id, body) {
    return http.patch(`/wallets/${id}/set-banned`, body);
  }

  static editWallet(id, body) {
    return http.patch(`/wallets/${id}`, body);
  }

  static fetchWalletOptions() {
    return http.get('/wallets/info-options');
  }
}
