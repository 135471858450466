import User from './user';
import Common from './common';
import Wallet from './wallet';
import Category from './category';
import Transaction from './transaction';
import Report from './report';
import Post from './post';
import Summary from './summary';
import Whitelist from './whitelist';
import Dashboard from './Dashboard';

export default {
  User,
  Common,
  Wallet,
  Category,
  Transaction,
  Report,
  Post,
  Summary,
  Whitelist,
  Dashboard,
};
