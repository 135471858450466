import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  bindActionCreators,
  compose
} from 'redux';
import { omit } from 'lodash';
import {
  Field,
  withFormik
} from 'formik';
import {
  Form,
  Modal,
  Button
} from 'antd';
import * as Yup from 'yup';
import styled from 'styled-components';

import FieldValidation from '../../components/Form/FieldValidation';
import WithDirection from '../../settings/withDirection';

const ModalFooterWrapper = WithDirection(styled.div`
  .footerBtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center
  }

  .footerBtn > span {
    display: flex
  }
`);

const ModalContentWrapper = WithDirection(styled.div`
  .ant-form-item {
    margin-bottom: 5px;
  }

  .pass-note {
    color: red;
    font-size: 12px;
    text-align: right;
    padding: 10px 0;
    height: 20px;
  }
}
`);


const validationSchema = Yup.object()
  .shape({
    oldPassword: Yup.string()
      .min(6),
    newPassword: Yup.string()
      .min(6),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], 'Passwords must match.')
      .notRequired()
  });

class ChangePasswordModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    requesting: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func
  };

  render() {
    const {
      visible,
      requesting,
      handleCancel,
      handleSubmit
    } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 17 }
      }
    };

    return (
      <Modal
        style={{ minWidth: 600 }}
        maskClosable={false}
        title="Change Password"
        visible={visible}
        onCancel={handleCancel}
        footer={
          <ModalFooterWrapper>
            <div className="footerBtn">
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={requesting}
              >
                Change Password
              </Button>
            </div>

          </ModalFooterWrapper>
        }
      >
        <ModalContentWrapper>
          <Form {...formItemLayout} onSubmit={handleSubmit}>
            <Field
              name="oldPassword"
              label="Old Password"
              component={FieldValidation}
              type="password"
            />
            <Field
              name="newPassword"
              label="New Password"
              component={FieldValidation}
              type="password"
            />
            <Field
              name="confirmNewPassword"
              label="Confirm New Password"
              component={FieldValidation}
              type="password"
            />
          </Form>
        </ModalContentWrapper>

      </Modal>
    );
  }
}

export default compose(
  connect(
    null,
    dispatch => bindActionCreators({}, dispatch)
  ),
  withFormik({
    // Handle validation later
    validationSchema,
    validateOnChange: false,
    // Use mapPropsToValues to prevent warning of controlled component
    // Or set initial data fsrom container passing through props
    mapPropsToValues: (props) => {
      return {};
    },
    handleSubmit: (values, { props, setSubmitting, resetForm }) => {
      props.onSubmit(omit(values, 'confirmNewPassword'), resetForm);
      setSubmitting(false);
    }
  })
)(ChangePasswordModal);
