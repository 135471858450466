import { handleActions } from 'redux-actions';

import { getView } from '../utils/common';
import { getDefaultPath } from '../helpers/urlSync';

const preKeys = getDefaultPath();

const initialState = {
  collapsed: window.innerWidth <= 1220,
  view: getView(window.innerWidth),
  height: window.innerHeight,
  openDrawer: false,
  openKeys: preKeys,
  current: preKeys,
  upload: {
    result: null,
    error: null,
    requesting: false,
  },
  contracts: {
    result: [],
    error: null,
    requesting: false,
  },
  sold: {
    result: {},
    error: null,
    requesting: false,
  },
  wallet: {
    result: {},
    error: null,
    requesting: false,
  },
};

export const common = handleActions({
  CLOSE_ALL: (state) => ({
    ...state,
    current: [],
    openKeys: [],
  }),
  CHANGE_CURRENT: (state, { payload }) => ({
    ...state,
    current: payload,
  }),
  CHANGE_OPEN_KEYS: (state, { payload }) => ({
    ...state,
    openKeys: payload,
  }),
  TOGGLE_COLLAPSED: (state) => ({
    ...state,
    collapsed: !state.collapsed,
  }),
  TOGGLE_OPEN_DRAWER: (state) => ({
    ...state,
    openDrawer: !state.openDrawer,
  }),
  HANDLE_TOGGLE_ALL: (state, { payload }) => {
    if (state.view !== payload.view || payload.height !== state.height) {
      const height = payload.height ? payload.height : state.height;
      return {
        ...state,
        collapsed: payload.collapsed,
        view: payload.view,
        height,
      };
    }
    return state;
  },
  /** UPLOAD **/
  UPLOAD_MEDIA_REQUEST: (state) => ({
    ...state,
    upload: {
      ...state.upload,
      requesting: true,
    },
  }),
  UPLOAD_MEDIA_SUCCESS: (state, { payload }) => ({
    ...state,
    upload: {
      ...state.upload,
      requesting: false,
      result: payload.data,
      error: null,
    },
  }),
  UPLOAD_MEDIA_FAIL: (state, { payload }) => ({
    ...state,
    upload: {
      ...state.upload,
      requesting: false,
      error: payload.error,
    },
  }),
  FETCH_CONTRACTS_REQUEST: (state) => ({
    ...state,
    contracts: {
      ...state.contracts,
      requesting: true,
    },
  }),
  FETCH_CONTRACTS_SUCCESS: (state, { payload }) => ({
    ...state,
    contracts: {
      ...state.contracts,
      requesting: false,
      result: payload.data,
      error: null,
    },
  }),
  FETCH_CONTRACTS_FAIL: (state, { payload }) => ({
    ...state,
    contracts: {
      ...state.contracts,
      requesting: false,
      error: payload.error,
    },
  }),
  STATISTICS_SOLD_REQUEST: (state) => ({
    ...state,
    sold: {
      ...state.sold,
      requesting: true,
    },
  }),
  STATISTICS_SOLD_SUCCESS: (state, { payload }) => ({
    ...state,
    sold: {
      ...state.sold,
      requesting: false,
      result: payload.data,
      error: null,
    },
  }),
  STATISTICS_SOLD_FAIL: (state, { payload }) => ({
    ...state,
    sold: {
      ...state.sold,
      requesting: false,
      error: payload.error,
    },
  }),
  STATISTICS_WALLETS_REQUEST: (state) => ({
    ...state,
    wallet: {
      ...state.wallet,
      requesting: true,
    },
  }),
  STATISTICS_WALLETS_SUCCESS: (state, { payload }) => ({
    ...state,
    wallet: {
      ...state.wallet,
      requesting: false,
      result: payload.data,
      error: null,
    },
  }),
  STATISTICS_WALLETS_FAIL: (state, { payload }) => ({
    ...state,
    wallet: {
      ...state.wallet,
      requesting: false,
      error: payload.error,
    },
  }),
  SET_INITIAL_USER_DATA: () => initialState,
}, initialState);

export default common;
