import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Radio
} from 'antd';
import { get } from 'lodash';

class RadioValidation extends Component {
  static propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    form: PropTypes.object,
    field: PropTypes.object,
    options: PropTypes.array,
    labelName: PropTypes.string,
    valueName: PropTypes.string,
    placeholder: PropTypes.string,
    containerClassName: PropTypes.string,
    isDisable: PropTypes.bool
  };

  static defaultProps = {
    options: []
  };

  onChange = e => {
    const { form, field, onChange } = this.props;
    const { setFieldValue } = form;

    setFieldValue(field.name, e.target.value);

    if (onChange) {
      onChange(e.target.value);
    }
  };

  render() {
    const {
      id,
      isButton,
      form,
      label,
      field,
      options,
      labelName,
      valueName,
      isDisable,
      containerClassName
    } = this.props;

    const { errors } = form;
    const fieldError = get(errors, `${field.name}`);
    const validateStatus = fieldError ? 'error' : '';

    const RadioItem = isButton ? Radio.Button : Radio;

    return (
      <Form.Item
        id={id}
        label={label}
        className={containerClassName}
        validateStatus={validateStatus}
        help={fieldError}
      >
        <Radio.Group disabled={isDisable} onChange={this.onChange} value={field.value} style={{ marginTop: 10 }}>
          {options.map((item, index) => (
            <RadioItem key={index} value={item[`${valueName}`]}>{item[`${labelName}`] || ''}</RadioItem>
          ))}
        </Radio.Group>
      </Form.Item>
    );
  }
}

export default RadioValidation;
