import React from 'react';
import { Link } from 'react-router-dom';

export default ({ collapsed }) => {
  return (
    <div className="isoLogoWrapper">
      <div>
        <h3>
          <Link to="/dashboard">
            <img style={{ height: 45, objectFit: 'contain' }} src={`${process.env.PUBLIC_URL}/favicon/android-chrome-192x192.png`} alt="logo" />
          </Link>
        </h3>
      </div>
    </div>
  );
};
