import React, { Component } from 'react';
import { connect } from 'react-redux';
import IntlMessages from '../../components/utility/intlMessages';
import { clearUserData, changePassword, createAccount } from '../../actions/user';
import TopbarDropdownWrapper from './topbarDropdown.style';
import ChangePasswordModal from './changePasswordModal';
import CreateAccountModal from './createAccount';
import { bindActionCreators } from 'redux';
import { message, Popover } from 'antd';
import { get } from 'lodash';

class TopbarUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      visibleChangePassword: false,
      visibleNewAccount: false,
    };
  }

  hide = () => {
    this.setState({ visible: false });
  }

  handleVisibleChange = () => {
    this.setState({ visible: !this.state.visible });
  }

  changePassword = values => {
    const { oldPassword, newPassword } = values;
    const { changePassword } = this.props;
    changePassword({ oldPassword, newPassword })
      .then(() => {
        const { changePass } = this.props;
        const { error } = changePass;
        if (error) {
          return message.error(get(error, 'data.message', 'Change Password Failed.'));
        }
        this.setState({ visibleChangePassword: false });
        return message.success('Change Password Success');
      });
  }

  createAccount = values => {
    const { createAccount } = this.props;
    createAccount(values)
      .then(() => {
        const { createAccountState } = this.props;
        const { error } = createAccountState;
        if (error) {
          return message.error(get(error, 'data.message', 'Create Failed.'));
        }
        this.setState({ visibleNewAccount: false });
        return message.success('Create Success');
      });
  }

  render() {
    const content = (
      <TopbarDropdownWrapper className="isoUserDropdown">
        <a className="isoDropdownLink" href="# " onClick={() => this.setState({ visible: false, visibleChangePassword: true })}>
          Change Password
        </a>
        <a className="isoDropdownLink" href="# " onClick={() => this.setState({ visible: false, visibleNewAccount: true })}>
          Create New Admin
        </a>
        <a className="isoDropdownLink" onClick={this.props.clearUserData} href="# ">
          <IntlMessages id="topbar.logout" />
        </a>
      </TopbarDropdownWrapper>
    );

    const {
      visibleChangePassword,
      visibleNewAccount
    } = this.state;

    return (
      <>
        <Popover
          content={content}
          trigger="click"
          visible={this.state.visible}
          onVisibleChange={this.handleVisibleChange}
          arrowPointAtCenter={true}
          placement="bottomLeft"
        >
          <div className="isoImgWrapper">
            <img alt="user" src={`${process.env.PUBLIC_URL}/favicon/android-chrome-192x192.png`} />
            <span className="userActivity online" />
          </div>
        </Popover>
        {
          visibleChangePassword && (
            <ChangePasswordModal
              visible={visibleChangePassword}
              handleCancel={() => this.setState({ visibleChangePassword: false })}
              onSubmit={this.changePassword}
            />
          )
        }
        {
          visibleNewAccount && (
            <CreateAccountModal
              visible={visibleNewAccount}
              handleCancel={() => this.setState({ visibleNewAccount: false })}
              onSubmit={this.createAccount}
            />
          )
        }
      </>
    );
  }
}


export default connect(
  state => ({
    changePass: state.user.changePass,
    createAccountState: state.user.createAccount,
  }),
  dispatch => bindActionCreators({
    createAccount,
    changePassword,
    clearUserData
  }, dispatch)
)(TopbarUser);
