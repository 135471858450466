import http from '../services/http';

export default class Common {
  static uploadMedia(body) {
    return http.post('/media/upload', body);
  }

  static fetchContracts() {
    return http.get('/contracts');
  }

  static fetchCategories() {
    return http.get('/categories');
  }

  static statisticsSold() {
    return http.get('/posts/statistics-sold');
  }

  static statisticsWallets() {
    return http.get('/wallets/statistics');
  }
}
