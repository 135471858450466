import http from '../services/http';

export default class Whitelist {
  static fetchWhitelists(params) {
    return http.get('/whitelists', {
      params
    });
  }

  static addWhitelist(body) {
    return http.post('/whitelists', body);
  }

  static deleteWhitelist(id) {
    return http.delete(`/whitelists/${id}`);
  }
}
