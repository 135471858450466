import { handleActions } from 'redux-actions';

const initialState = {
  overview: {
    result: {
      totalUser: 0,
      totalNft: 0,
      averagePrice: {
        ETH: 0,
        BNB: 0,
        NAFT:0
      },
      totalTokenBought: {
        ETH: 0,
        BNB: 0,
        NAFT:0
      }
    },
    error: null,
    requesting: false,
  },
  details: {
    result: {
      newUser: 0,
      userMinted: 0,
      userBought: 0,
      nftMintedPerUser: 0,
      nftPurchasedPerUser: 0,
      nftSold: 0,
      averagePrice: { BNB: 0, ETH: 0, NAFT: 0 },
      averageNewFollowings: 0,
      averageNewFollowers: 0,
      totalSaleValue: { BNB: 0, ETH: 0, NAFT: 0 },
      totalTokenBought: { BNB: 0, ETH: 0, NAFT: 0 },
      totalPlatformRevenue: { BNB: 0, ETH: 0, NAFT: 0 },
    },
    error: null,
    requesting: false
  },
};

export const dashboard = handleActions({
  FETCH_DETAILS_REQUEST: (state) => ({
    ...state,
    details: {
      ...state.details,
      requesting: true,
      error: null
    }
  }),
  FETCH_DETAILS_SUCCESS: (state, { payload }) => ({
    ...state,
    details: {
      ...state.details,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_DETAILS_FAIL: (state, { payload }) => ({
    ...state,
    details: {
      ...state.details,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  // Overview
  FETCH_OVERVIEW_REQUEST: (state) => ({
    ...state,
    overview: {
      ...state.overview,
      requesting: true,
      error: null
    }
  }),
  FETCH_OVERVIEW_SUCCESS: (state, { payload }) => ({
    ...state,
    overview: {
      ...state.overview,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_OVERVIEW_FAIL: (state, { payload }) => ({
    ...state,
    overview: {
      ...state.overview,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
}, initialState);

export default dashboard;
