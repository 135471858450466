import http from '../services/http';

export default class Summary {
  static getSummaryByKey(key) {
    return http.get(`/summaries/${key}`);
  }

  static updateSummaryByKey(key, value) {
    return http.patch(`/summaries/${key}`, { value });
  }
}
