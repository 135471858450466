import { handleActions } from 'redux-actions';

const initialState = {
  transactions: {
    result: null,
    error: null,
    requesting: false
  }
};

export const transaction = handleActions({
  FETCH_TRANSACTIONS_REQUEST: (state) => ({
    ...state,
    transactions: {
      ...state.transactions,
      requesting: true,
      error: null
    }
  }),
  FETCH_TRANSACTIONS_SUCCESS: (state, { payload }) => ({
    ...state,
    transactions: {
      ...state.transactions,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_TRANSACTIONS_FAIL: (state, { payload }) => ({
    ...state,
    transactions: {
      ...state.transactions,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  SET_INITIAL_USER_DATA: () => initialState,
}, initialState);

export default transaction;
