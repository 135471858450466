import { handleActions } from 'redux-actions';

const initialState = {
  whitelists: {
    result: [],
    error: null,
    requesting: false
  },
  addWhitelist: {
    result: null,
    error: null,
    requesting: false
  },
  deleteWhitelist: {
    result: null,
    error: null,
    requesting: false
  }
};

export const category = handleActions({
  FETCH_WHITELISTS_REQUEST: (state) => ({
    ...state,
    whitelists: {
      ...state.whitelists,
      requesting: true,
      error: null
    }
  }),
  FETCH_WHITELISTS_SUCCESS: (state, { payload }) => ({
    ...state,
    whitelists: {
      ...state.whitelists,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_WHITELISTS_FAIL: (state, { payload }) => ({
    ...state,
    whitelists: {
      ...state.whitelists,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  ADD_WHITELIST_REQUEST: (state) => ({
    ...state,
    addWhitelist: {
      ...state.addWhitelist,
      requesting: true,
      error: null
    }
  }),
  ADD_WHITELIST_SUCCESS: (state, { payload }) => ({
    ...state,
    addWhitelist: {
      ...state.addWhitelist,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  ADD_WHITELIST_FAIL: (state, { payload }) => ({
    ...state,
    addWhitelist: {
      ...state.addWhitelist,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  DELETE_WHITELIST_REQUEST: (state) => ({
    ...state,
    deleteWhitelist: {
      ...state.deleteWhitelist,
      requesting: true,
      error: null
    }
  }),
  DELETE_WHITELIST_SUCCESS: (state, { payload }) => ({
    ...state,
    deleteWhitelist: {
      ...state.deleteWhitelist,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  DELETE_WHITELIST_FAIL: (state, { payload }) => ({
    ...state,
    deleteWhitelist: {
      ...state.deleteWhitelist,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  SET_INITIAL_USER_DATA: () => initialState,
}, initialState);

export default category;
