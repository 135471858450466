import { handleActions } from 'redux-actions';

const initialState = {
  summaries: [],
  error: null,
  requesting: false
};

export const summary = handleActions({
  FETCH_SUMMARY_REQUEST: state => ({
    ...state,
    requesting: true
  }),
  FETCH_SUMMARY_SUCCESS: (state, { payload }) => ({
    ...state,
    summaries: payload.data,
    requesting: false
  }),
  FETCH_SUMMARY_FAIL: (state, { payload }) => ({
    ...state,
    error: payload.error,
    requesting: false
  }),
  UPDATE_SUMMARY_REQUEST: state => ({
    ...state,
    requesting: true
  }),
  UPDATE_SUMMARY_SUCCESS: (state, { payload }) => ({
    ...state,
    summaries: payload.data,
    requesting: false
  }),
  UPDATE_SUMMARY_FAIL: (state, { payload }) => ({
    ...state,
    error: payload.error,
    requesting: false
  })
}, initialState);

export default summary;
