import localForage from 'localforage';
import { persistReducer } from 'redux-persist';

import user from './user';
import common from './common';
import wallet from './wallet';
import category from './category';
import transaction from './transaction';
import report from './report';
import post from './post';
import summary from './summary';
import whitelist from './whitelist';
import dashboard from './dashboard';

const userPersistConfig = {
  key: 'user',
  storage: localForage,
  whitelist: ['login']
};

export default {
  user: persistReducer(userPersistConfig, user),
  common,
  wallet,
  category,
  transaction,
  report,
  post,
  summary,
  whitelist,
  dashboard
};
