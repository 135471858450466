import asyncComponent from '../../helpers/AsyncFunc';

const routes = [
  {
    path: '/',
    exact: true,
    component: asyncComponent(() => import('../Pages/Dashboard'))
  },
  {
    path: '/dashboard',
    exact: true,
    component: asyncComponent(() => import('../Pages/Dashboard'))
  },
  {
    path: '/wallets',
    exact: true,
    component: asyncComponent(() => import('../Pages/Wallets'))
  },
  {
    path: '/login',
    exact: true,
    component: asyncComponent(() => import('../Pages/Login'))
  },
  {
    path: '/categories',
    exact: true,
    component: asyncComponent(() => import('../Pages/Categories'))
  },
  {
    path: '/transactions',
    exact: true,
    component: asyncComponent(() => import('../Pages/Transactions'))
  },
  {
    path: '/setting',
    exact: true,
    component: asyncComponent(() => import('../Pages/Setting'))
  },
  {
    path: '/report',
    exact: true,
    component: asyncComponent(() => import('../Pages/Report'))
  },
  {
    path: '/posts',
    exact: true,
    component: asyncComponent(() => import('../Pages/Posts'))
  },
  {
    path: '/whitelists',
    exact: true,
    component: asyncComponent(() => import('../Pages/Whitelists'))
  },
];

export default routes;
