import { handleActions } from 'redux-actions';

const initialState = {
  categories: {
    result: [],
    error: null,
    requesting: false
  },
  addCategory: {
    result: null,
    error: null,
    requesting: false
  },
  editCategory: {
    result: null,
    error: null,
    requesting: false
  },
  deleteCategory: {
    result: null,
    error: null,
    requesting: false
  },
  allCategories: {
    result: [],
    error: null,
    requesting: false
  }
};

export const category = handleActions({
  FETCH_ALL_CATEGORIES_REQUEST: (state) => ({
    ...state,
    allCategories: {
      ...state.allCategories,
      requesting: true,
      error: null
    }
  }),
  FETCH_ALL_CATEGORIES_SUCCESS: (state, { payload }) => ({
    ...state,
    allCategories: {
      ...state.allCategories,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_ALL_CATEGORIES_FAIL: (state, { payload }) => ({
    ...state,
    allCategories: {
      ...state.allCategories,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  FETCH_CATEGORIES_REQUEST: (state) => ({
    ...state,
    categories: {
      ...state.categories,
      requesting: true,
      error: null
    }
  }),
  FETCH_CATEGORIES_SUCCESS: (state, { payload }) => ({
    ...state,
    categories: {
      ...state.categories,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_CATEGORIES_FAIL: (state, { payload }) => ({
    ...state,
    categories: {
      ...state.categories,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  ADD_CATEGORY_REQUEST: (state) => ({
    ...state,
    addCategory: {
      ...state.addCategory,
      requesting: true,
      error: null
    }
  }),
  ADD_CATEGORY_SUCCESS: (state, { payload }) => ({
    ...state,
    addCategory: {
      ...state.addCategory,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  ADD_CATEGORY_FAIL: (state, { payload }) => ({
    ...state,
    addCategory: {
      ...state.addCategory,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  EDIT_CATEGORY_REQUEST: (state) => ({
    ...state,
    editCategory: {
      ...state.editCategory,
      requesting: true,
      error: null
    }
  }),
  EDIT_CATEGORY_SUCCESS: (state, { payload }) => ({
    ...state,
    editCategory: {
      ...state.editCategory,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  EDIT_CATEGORY_FAIL: (state, { payload }) => ({
    ...state,
    editCategory: {
      ...state.editCategory,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  DELETE_CATEGORY_REQUEST: (state) => ({
    ...state,
    deleteCategory: {
      ...state.deleteCategory,
      requesting: true,
      error: null
    }
  }),
  DELETE_CATEGORY_SUCCESS: (state, { payload }) => ({
    ...state,
    deleteCategory: {
      ...state.deleteCategory,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  DELETE_CATEGORY_FAIL: (state, { payload }) => ({
    ...state,
    deleteCategory: {
      ...state.deleteCategory,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  SET_INITIAL_USER_DATA: () => initialState,
}, initialState);

export default category;
