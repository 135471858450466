import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  bindActionCreators,
  compose
} from 'redux';
import { omit } from 'lodash';
import {
  Field,
  withFormik
} from 'formik';
import {
  Form,
  Modal,
  Button
} from 'antd';
import * as Yup from 'yup';
import styled from 'styled-components';

import FieldValidation from '../../components/Form/FieldValidation';
import WithDirection from '../../settings/withDirection';
import RadioValidation from '../../components/Form/RadioValidation';
import UpdateImageValidation from '../../components/Form/UpdateImageValidation';

const ModalFooterWrapper = WithDirection(styled.div`
  .footerBtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center
  }

  .footerBtn > span {
    display: flex
  }
`);

const ModalContentWrapper = WithDirection(styled.div`
  .ant-form-item {
    margin-bottom: 5px;
  }

  .pass-note {
    color: red;
    font-size: 12px;
    text-align: right;
    padding: 10px 0;
    height: 20px;
  }
`);

const validationSchema = Yup.object()
  .shape({
    email: Yup.string()
      .email('Invalid email address')
      .required('Required'),
    username: Yup.string()
      .min(5)
      .matches(/^[a-z0-9_-]{3,15}$/i)
      .required('Required'),
    password: Yup
      .string()
      .min(6)
      .required('Please enter your password'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], 'Passwords must match.')
      .required(),
    fistName: Yup.string(),
    lastName: Yup.string()
  });

const initFormData = [
  {
    name: 'email',
    label: 'Email',
    component: FieldValidation
  },
  {
    name: 'username',
    label: 'username',
    component: FieldValidation
  },
  {
    name: 'password',
    label: 'Password',
    component: FieldValidation,
    type: 'password',
    inputProps: {
      autoComplete: 'new-password'
    }
  },
  {
    name: 'confirmPassword',
    label: 'Confirm Password',
    component: FieldValidation,
    type: 'password'
  },
  {
    name: 'firstName',
    label: 'First Name',
    component: FieldValidation
  },
  {
    name: 'lastName',
    label: 'Last Name',
    component: FieldValidation
  }
];

class CreateAccountModal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    requesting: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func
  };

  render() {
    const {
      visible,
      requesting,
      handleCancel,
      handleSubmit
    } = this.props;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 24 },
        xl: { span: 17 }
      }
    };

    return (
      <Modal
        style={{ minWidth: 600 }}
        maskClosable={false}
        title="Change Password"
        visible={visible}
        onCancel={handleCancel}
        footer={
          <ModalFooterWrapper>
            <div className="footerBtn">
              <Button
                type="primary"
                onClick={handleSubmit}
                loading={requesting}
              >
                Create New Admin
              </Button>
            </div>

          </ModalFooterWrapper>
        }
      >
        <ModalContentWrapper>
          <Form {...formItemLayout} onSubmit={handleSubmit} autoComplete="off">
            {
              initFormData.map((item, index) => (
                <Field
                  key={index}
                  {...item}
                />
              ))
            }
          </Form>
        </ModalContentWrapper>

      </Modal>
    );
  }
}

export default compose(
  connect(
    null,
    dispatch => bindActionCreators({}, dispatch)
  ),
  withFormik({
    // Handle validation later
    validationSchema,
    validateOnChange: false,
    // Use mapPropsToValues to prevent warning of controlled component
    // Or set initial data fsrom container passing through props
    mapPropsToValues: (props) => {
      return {};
    },
    handleSubmit: (values, { props, setSubmitting, resetForm }) => {
      props.onSubmit(omit(values, 'confirmPassword'), resetForm);
      setSubmitting(false);
    }
  })
)(CreateAccountModal);
