import React, { Component } from 'react';
import { Popover } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import IntlMessages from '../../components/utility/intlMessages';
import TopbarDropdownWrapper from './topbarDropdown.style';

import themes from '../../settings/themes';
const { themedefault: customizedTheme } = themes;

class TopbarNotification extends Component {
  constructor(props) {
    super(props);
    this.handleVisibleChange = this.handleVisibleChange.bind(this);
    this.hide = this.hide.bind(this);
    this.state = {
      visible: false
    };
  }

  hide() {
    this.setState({ visible: false });
  }

  handleVisibleChange() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    const { notifications } = this.props;
    const { result: data } = notifications;
    const content = (
      <TopbarDropdownWrapper className="topbarNotification">
        <div className="isoDropdownHeader">
          <h3>
            <IntlMessages id="sidebar.notification" />
          </h3>
        </div>
        <div className="isoDropdownBody">
          {data.map(notification => (
            <a className="isoDropdownListItem" key={notification.id} href="# ">
              <h5>{get(notification, 'data.title', '')}</h5>
              <p>{get(notification, 'data.sentence', '')}</p>
            </a>
          ))}
        </div>
        <a className="isoViewAllBtn" href="# " onClick={() => this.hide()}>
          <IntlMessages id="topbar.close" />
        </a>
      </TopbarDropdownWrapper>
    );
    return (
      <Popover
        content={content}
        trigger="click"
        visible={this.state.visible}
        onVisibleChange={this.handleVisibleChange}
        placement="bottomLeft"
      >
        <div className="isoIconWrapper">
          <i
            className="ion-android-notifications"
            style={{ color: customizedTheme.textColor }}
          />
          <span>10</span>
        </div>
      </Popover>
    );
  }
}

export default connect(state => ({
  // notifications: state.common.notifications
  notifications: { result: [] }
}))(TopbarNotification);
