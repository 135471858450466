import { handleActions } from 'redux-actions';

const initialState = {
  reports: {
    result: [],
    error: null,
    requesting: false
  },
  reportStatus: {
    result: null,
    error: null,
    requesting: false
  },
  notifyEmail: {
    result: null,
    error: null,
    requesting: false
  },
  deleteComment: {
    result: null,
    error: null,
    requesting: false
  }
};

export const report = handleActions({
  FETCH_REPORTS_REQUEST: (state) => ({
    ...state,
    reports: {
      ...state.reports,
      requesting: true,
      error: null
    }
  }),
  FETCH_REPORTS_SUCCESS: (state, { payload }) => ({
    ...state,
    reports: {
      ...state.reports,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_REPORTS_FAIL: (state, { payload }) => ({
    ...state,
    reports: {
      ...state.reports,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  SET_REPORT_STATUS_REQUEST: (state) => ({
    ...state,
    reportStatus: {
      ...state.reportStatus,
      requesting: true,
      error: null
    }
  }),
  SET_REPORT_STATUS_SUCCESS: (state, { payload }) => ({
    ...state,
    reportStatus: {
      ...state.reportStatus,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SET_REPORT_STATUS_FAIL: (state, { payload }) => ({
    ...state,
    reportStatus: {
      ...state.reportStatus,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  SEND_NOTIFY_EMAIL_REQUEST: (state) => ({
    ...state,
    notifyEmail: {
      ...state.notifyEmail,
      requesting: true,
      error: null
    }
  }),
  SEND_NOTIFY_EMAIL_SUCCESS: (state, { payload }) => ({
    ...state,
    notifyEmail: {
      ...state.notifyEmail,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  SEND_NOTIFY_EMAIL_FAIL: (state, { payload }) => ({
    ...state,
    notifyEmail: {
      ...state.notifyEmail,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  DELETE_COMMENT_REQUEST: (state) => ({
    ...state,
    deleteComment: {
      ...state.deleteComment,
      requesting: true,
      error: null
    }
  }),
  DELETE_COMMENT_SUCCESS: (state, { payload }) => ({
    ...state,
    deleteComment: {
      ...state.deleteComment,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  DELETE_COMMENT_FAIL: (state, { payload }) => ({
    ...state,
    deleteComment: {
      ...state.deleteComment,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  SET_INITIAL_USER_DATA: () => initialState,
}, initialState);

export default report;
