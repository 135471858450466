export default {
  apiUrl: 'http://yoursite.com/api/'
};

const siteConfig = {
  siteName: 'TEAM FINANCE',
  siteIcon: 'ion-flash',
  footerText: 'Nafter ©2021 Created by Nafter Team'
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault'
};
const language = 'english';

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey'
};

export { siteConfig, language, themeConfig, jwtConfig };
