import { toNumber, times, get } from 'lodash';

export const getQueryParam = (name, defaultData = '') => {
  const q = window.location.search.match(new RegExp('[?&]' + name + '=([^&#]*)'));
  return q ? q[1] : defaultData;
};
export const getView = (width) => {
  let newView = 'MobileView';
  if (width > 1220) {
    newView = 'DesktopView';
  } else if (width > 767) {
    newView = 'TabView';
  }
  return newView;
};

export const createDefaultUploadFileFromUrl = url => {
  if (!url) {
    return null;
  }
  return {
    uid: Date.now(),
    url,
    status: 'done',
    name: url.split('/')
      .pop() || ''
  };
};

export const formatCurrency = (currency, fixed = 0) => {
  if (!currency) {
    return 0;
  }
  currency = toNumber(currency);
  if (typeof currency === 'number') {
    return String(fixedFloat(currency, fixed))
      .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    // return currency.toFixed(fixed).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
  }
  return currency
    // eslint-disable-next-line
    .replace(/[(a-zA-Z)\s\_\,\-]+/g, '')
    .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
};

const fixedFloat = (input, decimals) => {
  const arr = input.toString()
    .split('.');
  if (arr.length === 1) {
    return decimals === 0
      ? input
      : [input,
        '.',
        times(decimals, () => '0')
          .join('')].join('');
  }
  const int = arr[0];
  const max = arr[1].length;
  const dec = arr[1].substr(0, decimals > max ? max : decimals);
  return decimals === 0
    ? int
    : [int,
      '.',
      dec.length >= decimals
        ? dec
        : (dec +
          times(decimals - dec.length, () => '0')
            .join(''))].join('');
};

const blockchain = {
  ethereum: {
    '0x1': 'Ethereum Mainnet',
    '0x3': 'Ethereum Testnet'
  },
  bsc: {
    '0x38': 'BSC Mainnet',
    '0x61': 'BSC Testnet'
  },
  avax: {
    '0xa86a': 'Avax Mainnet',
    '0xa869': 'Avax Testnet'
  }
};

export const networkDisplay = (network, chainId) => {
  return get(blockchain, `${network}.${chainId}`, blockchain.ethereum['0x1']);
};
