import { handleActions } from 'redux-actions';

const initialState = {
  posts: {
    result: [],
    error: null,
    requesting: false
  },
  toggleDisable: {
    result: null,
    error: null,
    requesting: false
  },
  editPost: {
    result: null,
    error: null,
    requesting: false
  }
};

export const post = handleActions({
  FETCH_POSTS_REQUEST: (state) => ({
    ...state,
    posts: {
      ...state.posts,
      requesting: true,
      error: null
    }
  }),
  FETCH_POSTS_SUCCESS: (state, { payload }) => ({
    ...state,
    posts: {
      ...state.posts,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  FETCH_POSTS_FAIL: (state, { payload }) => ({
    ...state,
    posts: {
      ...state.posts,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  TOGGLE_DISABLE_REQUEST: (state) => ({
    ...state,
    toggleDisable: {
      ...state.toggleDisable,
      requesting: true,
      error: null
    }
  }),
  TOGGLE_DISABLE_SUCCESS: (state, { payload }) => ({
    ...state,
    toggleDisable: {
      ...state.toggleDisable,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  TOGGLE_DISABLE_FAIL: (state, { payload }) => ({
    ...state,
    toggleDisable: {
      ...state.toggleDisable,
      requesting: false,
      result: null,
      error: payload.error
    }
  }),
  EDIT_POST_REQUEST: (state) => ({
    ...state,
    editPost: {
      ...state.editPost,
      requesting: true,
      error: null
    }
  }),
  EDIT_POST_SUCCESS: (state, { payload }) => ({
    ...state,
    editPost: {
      ...state.editPost,
      requesting: false,
      result: payload.data,
      error: null
    }
  }),
  EDIT_POST_FAIL: (state, { payload }) => ({
    ...state,
    editPost: {
      ...state.editPost,
      requesting: false,
      result: null,
      error: payload.error
    }
  })
}, initialState);

export default post;
