import { createActions, createAction } from 'redux-actions';
import { get } from 'lodash';
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';

import Api from '../api';
import http from '../services/http';

/** LOGIN **/
const { firstLoginRequest, firstLoginSuccess, firstLoginFail } = createActions({
  FIRST_LOGIN_REQUEST: () => { },
  FIRST_LOGIN_SUCCESS: (data, qrcodeImage) => ({ data, qrcodeImage }),
  FIRST_LOGIN_FAIL: error => ({ error })
});

export const firstLogin = credentials => dispatch => {
  dispatch(firstLoginRequest());
  return Api.User.firstLogin(credentials)
    .then(async ({ data }) => {
      const { token, generateSecret } = data;
      let qrcodeImage = null;
      if (generateSecret) {
        try {
          const { data: image } = await Api.User.generateQrcode({ token });
          qrcodeImage = 'data:image/png;base64,' + Buffer.from(image, 'binary').toString('base64');
        } catch (e) {
          throw e;
        }
      }
      dispatch(firstLoginSuccess(data, qrcodeImage));
    })
    .catch((error) => {
      dispatch(firstLoginFail(error));
    });
};

const { loginRequest, loginSuccess, loginFail } = createActions({
  LOGIN_REQUEST: () => { },
  LOGIN_SUCCESS: (data, qrcodeImage) => ({ data, qrcodeImage }),
  LOGIN_FAIL: error => ({ error })
});

export const login = credentials => dispatch => {
  dispatch(loginRequest());
  http.setAuthorizationHeader('');
  return Api.User.login(credentials)
    .then(({ data }) => {
      const { token } = data;
      http.setAuthorizationHeader(token);
      dispatch(loginSuccess(data));
    })
    .catch((error) => {
      dispatch(loginFail(error));
    });
};

const { changePasswordRequest, changePasswordSuccess, changePasswordFail } = createActions({
  CHANGE_PASSWORD_REQUEST: () => { },
  CHANGE_PASSWORD_SUCCESS: (data, qrcodeImage) => ({ data, qrcodeImage }),
  CHANGE_PASSWORD_FAIL: error => ({ error })
});

export const changePassword = body => dispatch => {
  dispatch(changePasswordRequest());
  return Api.User.changePassword(body)
    .then(({ data }) => {
      dispatch(changePasswordSuccess(data));
    })
    .catch((error) => {
      dispatch(changePasswordFail(error));
    });
};

const { forgotPasswordRequest, forgotPasswordSuccess, forgotPasswordFail } = createActions({
  FORGOT_PASSWORD_REQUEST: () => { },
  FORGOT_PASSWORD_SUCCESS: (data, qrcodeImage) => ({ data, qrcodeImage }),
  FORGOT_PASSWORD_FAIL: error => ({ error })
});

export const forgotPassword = params => dispatch => {
  dispatch(forgotPasswordRequest());
  return Api.User.forgotPassword(params)
    .then(({ data }) => {
      dispatch(forgotPasswordSuccess(data));
    })
    .catch((error) => {
      dispatch(forgotPasswordFail(error));
    });
};

const { resetPasswordRequest, resetPasswordSuccess, resetPasswordFail } = createActions({
  RESET_PASSWORD_REQUEST: () => { },
  RESET_PASSWORD_SUCCESS: (data, qrcodeImage) => ({ data, qrcodeImage }),
  RESET_PASSWORD_FAIL: error => ({ error })
});

export const resetPassword = params => dispatch => {
  dispatch(resetPasswordRequest());
  return Api.User.resetPassword(params)
    .then(({ data }) => {
      dispatch(resetPasswordSuccess(data));
    })
    .catch((error) => {
      dispatch(resetPasswordFail(error));
    });
};

const { createAccountRequest, createAccountSuccess, createAccountFail } = createActions({
  CREATE_ACCOUNT_REQUEST: () => { },
  CREATE_ACCOUNT_SUCCESS: (data, qrcodeImage) => ({ data, qrcodeImage }),
  CREATE_ACCOUNT_FAIL: error => ({ error })
});

export const createAccount = params => dispatch => {
  dispatch(createAccountRequest());
  return Api.User.createAccount(params)
    .then(({ data }) => {
      dispatch(createAccountSuccess(data));
    })
    .catch((error) => {
      dispatch(createAccountFail(error));
    });
};

/* clear data after user logout */
const setInitialUserData = createAction('SET_INITIAL_USER_DATA');

export const clearUserData = () => (dispatch) => {
  http.setAuthorizationHeader('');
  dispatch(setInitialUserData());
};

const { connectWalletRequest, connectWalletSuccess, connectWalletFail } = createActions({
  CONNECT_WALLET_REQUEST: () => { },
  CONNECT_WALLET_SUCCESS: data => ({ data }),
  CONNECT_WALLET_FAIL: error => ({ error })
});

export const setUserAccounts = createAction('SET_USER_ACCOUNTS');
export const setChainId = createAction('SET_NETWORK');
export const setWrongNetwork = createAction('SET_WRONG_NETWORK');

const ALLOWED_NETWORKS = ['0x1', '0x4', '0x38', '0x61'];

export const validateNetwork = (chainId) => (dispatch, getState) => {
  const { wrongNetwork } = getState().user;

  if (chainId) {
    const isValid = ALLOWED_NETWORKS.indexOf(chainId) !== -1;

    if (!isValid) {
      dispatch(setWrongNetwork(true));
    } else if (isValid) {
      dispatch(setWrongNetwork(false));
    }
  } else if (wrongNetwork) {
    dispatch(setWrongNetwork(false));
  }
};

export const setNetwork = (chainId) => (dispatch) => {
  dispatch(setChainId(chainId));
  dispatch(validateNetwork(chainId));
};

export const connectMetaMask = () => async (dispatch) => {
  dispatch(connectWalletRequest());
  // Check metamask is install or not
  if (window.ethereum) {
    const provider = await detectEthereumProvider();
    // If the provider returned by detectEthereumProvider is not the same as
    // window.ethereum, something is overwriting it, perhaps another wallet.
    if (provider !== window.ethereum) {
      window.web3 = new Web3(provider);
    } else {
      window.web3 = new Web3(window.ethereum);
    }

    window.ethereum.on('accountsChanged', (accounts) => {
      dispatch(setUserAccounts({ accounts }));
    });

    window.ethereum.on('chainChanged', async (chainId) => {
      window.web3 = new Web3(window.ethereum);
      const accounts = await window.web3.eth.getAccounts();
      let balance;

      dispatch(setNetwork(chainId));

      if (accounts && accounts[0]) {
        balance = await window.web3.eth.getBalance(accounts[0]);
        dispatch(setUserAccounts({ balance, chainId }));
      }
    });

    return window.ethereum.request({ method: 'eth_requestAccounts' })
      .then(async () => {
        dispatch(connectWalletSuccess());

        const chainId = window.ethereum.chainId;
        const accounts = await window.web3.eth.getAccounts();
        dispatch(setUserAccounts({ accounts, chainId }));
      })
      .catch(() => {
        dispatch(connectWalletFail());
      });
  }
  return new Promise((resolve, reject) => {
    const err = 'Metamask not install.';
    dispatch(connectWalletFail(err));
    reject(err);
  });
};
